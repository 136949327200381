import React, { PureComponent } from "react";
import wiki from "./img/wiki.jpg";
class Home extends PureComponent {
  render() {
    return (
      <div>
        <header>
          <div className="header-container">
            <div className="header-description">
              <h1>
              VI Ultramaraton Generała Józefa Dowbora Muśnickiego
              </h1>
              <h3>Lusowo, 27.12.2024</h3>
            </div>
          </div>
        </header>
        <div className="content-container">
          <div className="content">
            <h3>Informacje</h3>
            <p>
              106 km na 106 rocznicę wybuchu Powstania Wielkopolskiego
              Jest to już piąta edycja biegu, który upamiętnia bohaterów Powstania Wielkopolskiego (1918 r.).        
              Tym razem 106 zawodników będzie mogło zmierzyc się z dystansem 106 kilometrów.              
            </p>            
            <p><a href="./wyniki-2024.pdf"><span style={{color:'red',fontWeight:"bold", fontSize:28}}>Wyniki - 2024</span></a></p>
            <p><a href="./wyniki-2023.pdf"><span style={{color:'red',fontWeight:"bold", fontSize:28}}>Wyniki - 2023</span></a></p>
            <p><a href="./wyniki-2022.pdf"><span style={{color:'red',fontWeight:"bold", fontSize:28}}>Wyniki - 2022</span></a></p>
            <p><a href="./wyniki-2021.pdf"><span style={{color:'red',fontWeight:"bold", fontSize:28}}>Wyniki - 2021</span></a></p>
            <h3>Trasa</h3>
            <p>
              Całkowity dystans biegu to 106 kilometry, na które składa się 10 okrążeń, w tym pierwsze okrążenie ma 15 km, a
              pozostałe po 10 km. Trasa wiedzie przez ścieżki o naturalnym podłożu terenu wśród okolicznych łąk i lasów.
              Zawodnik ma możliwość pokonania dowolnej liczby okrążeń. Do klasyfikacji zaliczane są tylko pełne okrążenia.
              <iframe
                title="Trasa biegu"
                frameBorder="0"
                scrolling="no"
                src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=44342960&measures=on&title=off&near=off&images=off&maptype=M&metricunits=on"
                width="100%"
                height="700"
              ></iframe>
              <br />
              <em>Trasa pojedynczego okrążenia</em>
            </p>
            <h3>Powstanie</h3>
            <p>
              Każdemu zainteresowanemu polecamy zapoznać się z genezą wybuchu
              oraz przebiegiem Powstania Wielkopolskiego.
              <a href="https://pl.wikipedia.org/wiki/Powstanie_wielkopolskie">
                <img src={wiki} alt="Wikipedia - Powstanie wielkopolskie"></img>
              </a>
              <em>
                <a href="https://pl.wikipedia.org/wiki/Powstanie_wielkopolskie">
                  <br></br>
                  Wikipedia o Powstaniu Wielkopolskiem
                </a>
              </em>
            </p>
            <h3>Podziękowania</h3>
            <p>
              Serdecznie dziękujemy darczyńcom i przedsiębiorstwom, które
              pomogły w organizacji biegu.
            </p>         
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {};

export default Home;
